<script>
import { useRouter } from "vue-router";
import {computed} from "vue";
import EnquiryStatusIcon from "@/components/atoms/enquiry-status-icon/ed-atom-enquiry-status-icon.vue";
import EnquiryFeedbackButton from "@/components/atoms/enquiry-feedback-button/ed-atom-enquiry-feedback-buttons.vue";

export default {
  name: "EnquiryDetails",
  components: {EnquiryStatusIcon, EnquiryFeedbackButton},
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const router = useRouter();

    const isVerified = computed(() => {
      if (props.enquiry.answerSetCSV.split(',').includes('58')
        || props.enquiry.answerSetCSV.split(',').includes('1287')) {
        return true;
      }
      return false;
    });

    const noFlowAvailable = computed(() => props.enquiry.workType.qualificationFlow === undefined);

    function navigate() {
      if (isVerified.value || noFlowAvailable.value) {
        router.push({ name: 'quoteDetails', params: { id: props.enquiry.quoteCode }})
      } else {
        router.push({name: 'self-qualify', params: {id: props.enquiry.quoteCode}})
      }
    }

    function dateFormat(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-GB');
    }

    return {navigate, dateFormat};
  }
}
</script>

<template>
  <div class="cursor-pointer">
    <div class="row" @click="navigate">
      <div :class="['col-12', small ? 'enquiry-heading--small' : 'enquiry-heading']">
        {{ enquiry.workType.description }}
        <enquiry-status-icon :enquiry="enquiry" />
      </div>
    </div>
    <div class="row" @click="navigate">
      <div :class="['col-12', small ? 'enquiry-date--small' : 'enquiry-date']">
        {{ $t('created') }}: {{ dateFormat(enquiry.requestDate) }}
      </div>
    </div>
    <enquiry-feedback-button :enquiry="enquiry" />
  </div>
</template>

<style scoped lang="scss">


</style>
