import {computed, watch} from "vue";
import {getSiteColors} from "@/services/sites";
import {useQualificationSite} from "@/utils/useQualificationSite";

export function useColorWatcher(routeName) {
  const enquirerSite = useQualificationSite();
  const siteStyle = computed(() => enquirerSite.value !== null ? getSiteColors(enquirerSite.value) : null);

  watch([routeName, enquirerSite], async ([newName]) => {
    if (newName === 'self-qualify' && siteStyle.value !== null) {
      document.querySelector('#iosAccent').setAttribute('content', siteStyle.value.primary)
      document.documentElement.style.setProperty('--primary', siteStyle.value.primary)
      document.documentElement.style.setProperty('--secondary', siteStyle.value.secondary)
      document.documentElement.style.setProperty('--footer', siteStyle.value.footerText)
      document.documentElement.style.setProperty('--button', siteStyle.value.buttons)
      document.documentElement.style.setProperty('--button-hover', siteStyle.value.hoverButtons)
    } else {
      document.querySelector('#iosAccent').setAttribute('content', '#3C1E5F')
      document.documentElement.style.setProperty('--primary', '#3C1E5F')
      document.documentElement.style.setProperty('--secondary', '#B9B9B9')
      document.documentElement.style.setProperty('--footer', '#000')
      document.documentElement.style.setProperty('--button', '#FF6964')
      document.documentElement.style.setProperty('--button-hover', '#BA4A47')
    }
  });
}
