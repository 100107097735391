<script>
import {useEnquiryStore} from "@/store/enquiryStore";
import {storeToRefs} from "pinia";
import EnquiryDetails from "@/components/molecules/enquiry-details/ed-molecule-enquiry-details.vue";
import { useFeedbackStore } from "@/store/feedbackStore";

export default {
  name: "EnquiryListSmall",
  components: {EnquiryDetails},
  setup() {
    const enquiryStore = useEnquiryStore();
    const feedbackStore = useFeedbackStore();
    const {getEnquiries} = storeToRefs(enquiryStore);
    enquiryStore.fetchEnquiries();
    feedbackStore.fetchEnquiryFeedbackAnswers();

    return {getEnquiries};
  }
}
</script>

<template>
  <div class="col-12 d-flex flex-column">
    <enquiry-details
      v-for="enquiry in getEnquiries(2)"
      :key="enquiry.quoteCode"
      :enquiry="enquiry"
      :small="true"
      class="separated small-text"
    />
  </div>
</template>

<style scoped lang="scss">
</style>
