<template>
  <div class="from-group">
    <h3>{{ $t('login.loginEmail') }}</h3>
    <input id="email" v-model="email" type="email" required class="form-control">
    <p class="mt-2" v-if="error">{{error}}</p>
  </div>
  <button class="btn btn-orange mt-3" @click.stop.prevent="submit">
    {{ $t('login.submit') }}
  </button>
</template>

<script>
import {ref} from "vue";

export default {
  name: "LoginForm",
  setup(props, {emit}) {
    const email = ref('');
    const error = ref('');
    function submit() {
        if(email.value === '') {
            error.value = 'Please enter a valid email address';
            return;
        }

        emit('submit', email.value);
    }

    return {email, error, submit}
  }
}
</script>
